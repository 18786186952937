<template>
  <a href="" ref="gotochrome" style="display: none"></a>
  <div class="store">
    <div class="page">
      <div class="store-wrap withvideo">
        <section id="video-header" class="cover" v-if="withvideo">
          <div class="play" @click="videoOverlayStatus = true">
            <i class="material-icons-outlined">play_arrow</i> Trailer
          </div>
          <video
            autoplay
            muted
            loop
            crossorigin
            playsinline
            @loadeddata="videoLoaded"
          >
            <source
              :src="`bananza.mp4`"
              type="video/mp4"
              @error="withvideo = false"
            />
          </video>
        </section>
        <section
          id="img-cover"
          class="cover"
          v-else-if="!withvideo && withcover"
        >
          <picture>
            <source
              :srcset="`/uploads/${theme}/images/cover?type=webp&w=720`"
              type="image/webp"
            />
            <source
              :srcset="`/uploads/${theme}/images/cover?w=720`"
              type="image/jpg"
            />
            <img
              :src="`/uploads/${theme}/images/cover?w=720`"
              @error="withcover = false"
            />
          </picture>
        </section>

        <header id="app-title" class="container">
          <div class="img">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="img-container">
              <img src="../assets/logo.jpg" alt="MrBeast Casino" />
            </div>
          </div>
          <div id="app-name">
            <h2>MrBeast Casino</h2>
            <span>{{ $t("appName.appDescr") }}</span>
            <div>
              <span>{{ $t("appName.appAds") }}</span
              ><span>{{ $t("appName.appPurchase") }}</span>
            </div>
          </div>
        </header>
        <section id="app-stats" class="container">
          <div class="item">
            <div class="icon">4.9<span>★</span></div>
            <div class="desc">{{ $t("appStats.reviewsTitle") }}</div>
          </div>
          <div class="item">
            <div class="icon">510К+</div>
            <div class="desc">{{ $t("appStats.downloadsTitle") }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <i class="material-icons-outlined icon18">18_up_rating</i>
            </div>
            <div class="desc">{{ $t("appStats.rated") }}</div>
          </div>
        </section>
        <section id="download-sec">
          <div class="container">
            <button id="more-info" class="button none">
              {{ $t("moreInfoTitle") }}
            </button>

            <button
              v-if="
                !store.prompt &&
                !store.loading &&
                !openWeb &&
                !store.installed &&
                userDevice == 'Android'
              "
              id="install"
              class="button"
            >
              <span> {{ $t("preparingTitle") }}</span>
            </button>

            <button
              @click="openWebTimer"
              v-if="
                !store.prompt &&
                !store.loading &&
                openWeb &&
                !store.installed &&
                userDevice == 'Android'
              "
              id="install"
              class="button"
            >
              <span>{{ $t("openApp") }}</span>
            </button>

            <button v-if="userDevice != 'Android'" id="install" class="button">
              <span>{{ $t("notSupported") }}</span>
            </button>

            <button
              v-if="store.prompt && userDevice == 'Android'"
              @click="installApp"
              id="install"
              class="button"
            >
              <span v-if="!loading">{{ $t("install") }}</span>
              <span v-if="loading">{{ $t("installing") }}</span>
            </button>

            <button
              v-if="
                !store.prompt &&
                !store.loading &&
                store.installed &&
                userDevice == 'Android'
              "
              @click="openApp"
              id="install"
              class="button"
            >
              <span>{{ $t("openApp") }}</span>
            </button>

            <button @click="showNotice" id="how-to">
              {{ $t("howToInstall") }}
            </button>
          </div>
        </section>
        <section id="img-gallery">
          <div
            class="grid"
            style="grid-template-columns: repeat(4, max-content)"
          >
            <a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/1.jpg" type="image/webp" />
                <source srcset="../assets/1.jpg" type="image/jpg" />
                <img src="../assets/1.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/2.jpg" type="image/webp" />
                <source srcset="../assets/2.jpg" type="image/jpg" />
                <img src="../assets/2.jpg" /></picture></a
            ><a href="javascript:void(0);" class="img-item"
              ><picture
                ><source srcset="../assets/3.jpg" type="image/webp" />
                <source srcset="../assets/3.jpg" type="image/jpg" />
                <img src="../assets/3.jpg" /></picture
            ></a>
          </div>
        </section>
        <div id="img-overlay" class=""><img src="" /></div>
        <section id="about-sec">
          <div class="container">
            <div class="sec-title">
              <h3>{{ $t("aboutThisApp") }}</h3>
              <button @click="showAbout" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="app-short-desc">
              {{ $t("shortAppDescr") }}
            </div>
            <div class="app-meta">
              <span>{{ $t("meta.tag1") }}</span
              ><span>{{ $t("meta.tag2") }} </span>
            </div>
          </div>
        </section>
        <div ref="aboutRef" id="full-desc" class="fixed-screen">
          <header>
            <button @click="showAbout" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img src="../assets/logo.jpg" alt="MrBeast Casino" />
            </div>
            <div class="name">
              <div class="title-aps">MrBeast Casino</div>
              <span>{{ $t("detailsTitle") }}</span>
            </div>
          </header>
          <div class="container">
            <h3 class="title-aps">{{ $t("aboutThisApp") }}</h3>
            <div class="text">
              <p>😱{{ $t("aboutAppDescr.text1") }}</p>
              <p>
                {{ $t("aboutAppDescr.text2") }}
              </p>
              <p>
                {{ $t("aboutAppDescr.text3") }}
              </p>
              <p>
                {{ $t("aboutAppDescr.text4") }}
              </p>
              <p>🍬MrBeast Casino</p>
              <p>📖Book of Ra</p>
              <p>⚡Gates of Olympus</p>
              <p>🔮Plinko</p>
              <p>🐶Dog House</p>
              <p>🍭Sugar Rush</p>
              <p>🐺Wolf Gold and many others!</p>
            </div>
          </div>
          <div class="about-app">
            <div class="container">
              <div class="title-aps">{{ $t("appInfoTitle") }}</div>
              <div class="details-grid">
                <div class="row">
                  <div class="name">{{ $t("appVersion") }}</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("updatedOn") }}</div>
                  <span>{{ $t("month") }} 12, 2024</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("appStats.downloadsTitle") }}</div>
                  <span>512400+ {{ $t("downloadsSmallTitle") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("downloadSize") }}</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("developerTitle") }}</div>
                  <span>{{ $t("appName.appDescr") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("appName.releasedOn") }}</div>
                  <span>{{ $t("month") }} 4, 2024</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="ratings">
          <div class="container">
            <div class="sec-title">
              <h3>
                {{ $t("ratingAndReviews.title")
                }}<a
                  href="https://play.google.com/about/comment-posting-policy/"
                  target="_blank"
                  ><i class="material-icons-outlined">info</i></a
                >
              </h3>
              <button @click="showReviews" class="more_btn btn-icon">
                <i class="material-icons-outlined">arrow_forward</i>
              </button>
            </div>
            <div class="rating">
              <div class="left">
                <h2>4.9</h2>
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="half">★</span>
                </div>
                <p>43590</p>
              </div>
              <div class="right">
                <div class="row">
                  <span>5</span>
                  <div class="line">
                    <i style="width: 95%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>4</span>
                  <div class="line">
                    <i style="width: 80%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>3</span>
                  <div class="line">
                    <i style="width: 48%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>2</span>
                  <div class="line">
                    <i style="width: 24%"></i>
                  </div>
                </div>
                <div class="row">
                  <span>1</span>
                  <div class="line">
                    <i style="width: 10%"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="reviews">
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">21.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review1") }}
              </div>
              <div class="developer-answer">
                <div class="title-aps">
                  {{ $t("appName.appDescr") }}<span> 22.04.2024</span>
                </div>
                <div class="text">
                  {{ $t("ratingAndReviews.answer1") }}
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">28.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review2") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">20.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review3") }}
              </div>
              <!---->
            </div>
            <a href="javascript:void(0);" @click="showReviews" id="all-reviews"
              >See all reviews</a
            >
          </div>
        </section>
        <div ref="fullReviewsRef" id="full-reviews" class="fixed-screen">
          <header>
            <button @click="showReviews" class="back_btn btn-icon">
              <i class="material-icons-outlined">arrow_back</i>
            </button>
            <div class="img">
              <img
                src="../assets/logo.jpg"
                alt="MrBeast Casino"
                loading="lazy"
              />
            </div>
            <div class="name">
              <div class="title-aps">
                MrBeast Casino
                <div class="rating"><span></span></div>
              </div>
              <span>{{ $t("ratingAndReviews.title") }}</span>
            </div>
          </header>
          <div class="filter">
            <div class="container">
              <div class="left">{{ $t("allTitle") }}</div>
              <div class="right">
                {{ $t("mostRelevantTitle")
                }}<i class="material-icons-outlined">sort</i>
              </div>
            </div>
          </div>
          <div class="container reviews-grid">
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Noah.jpg"
                    alt="Noah"
                    loading="lazy"
                  />
                </div>
                <div class="name">Noah</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">02.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review4") }}
              </div>
              <div class="developer-answer">
                <div class="title-aps">Casino App. <span>03.04.2024</span></div>
                <div class="text">
                  {{ $t("ratingAndReviews.answer2") }}
                </div>
              </div>
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(183, 229, 47)">M</span>
                </div>
                <div class="name">Mason</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">28.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review2") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Michael.jpg"
                    alt="Michael"
                    loading="lazy"
                  />
                </div>
                <div class="name">Michael</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">20.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review3") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(207, 73, 73)">W</span>
                </div>
                <div class="name">William</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">24.04.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review5") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(68, 163, 193)">A</span>
                </div>
                <div class="name">Agnes</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">14.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review6") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Juliet.jpg"
                    alt="Juliet"
                    loading="lazy"
                  />
                </div>
                <div class="name">Juliet</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">01.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review7") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Graham.jpg"
                    alt="Graham"
                    loading="lazy"
                  />
                </div>
                <div class="name">Graham</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">12.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review8") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 155, 68)">H</span>
                </div>
                <div class="name">Hugh</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">16.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review9") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <img
                    src="../assets/reviews/Martin.jpg"
                    alt="Martin"
                    loading="lazy"
                  />
                </div>
                <div class="name">Martin</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">05.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review10") }}
              </div>
              <!---->
            </div>
            <div class="review-item">
              <header>
                <div class="img">
                  <span style="background: rgb(193, 68, 188)">S</span>
                </div>
                <div class="name">Scott</div>
                <button class="btn-icon">
                  <i class="material-icons-outlined">more_vert</i>
                </button>
              </header>
              <div class="meta">
                <div class="stars">
                  <span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span><span class="color">★</span
                  ><span class="color">★</span>
                </div>
                <div class="date">26.05.2024</div>
              </div>
              <div class="text">
                {{ $t("ratingAndReviews.review11") }}
              </div>
              <!---->
            </div>
          </div>
        </div>
        <section id="developer" class="">
          <div class="container">
            <div class="sec-title">
              <h3>{{ $t("developerContactTitle") }}</h3>
              <button class="more_btn btn-icon">
                <i class="material-icons-outlined">expand_more</i>
              </button>
            </div>
            <div class="grid">
              <a href="https://spinbeasttop.com/" target="_blank" class="row"
                ><i class="material-icons-outlined">public</i>
                <div class="name">{{ $t("websiteTitle") }}</div></a
              ><a href="mailto:https://spinbeasttop.com/" class="row"
                ><i class="material-icons-outlined">mail</i>
                <div class="name">
                  Email<span>support@spinbeasttop.com</span>
                </div></a
              ><a
                href="https://spinbeasttop.com/privacy-policy"
                target="_blank"
                class="row"
                ><i class="material-icons-outlined">shield</i>
                <div class="name">{{ $t("privacyPolicyTitle") }}</div></a
              >
            </div>
          </div>
        </section>
        <footer id="footer">
          <div class="container">
            <div class="sec-title"><h3>Google</h3></div>
            <div class="links">
              <a
                href="https://play.google.com/intl/en_en/about/play-terms/index.html"
                target="_blank"
                >{{ $t("termsOfServiceTitle") }}</a
              ><a href="https://policies.google.com/privacy" target="_blank">{{
                $t("privacyPolicyTitle")
              }}</a
              ><a
                href="http://developer.android.com/index.html"
                target="_blank"
                >{{ $t("forDevelopersTitle") }}</a
              ><a
                href="https://support.google.com/googleplay/?p=about_play"
                target="_blank"
                >{{ $t("aboutGooglePlayTitle") }}</a
              >
            </div>
            <p>©2024 Google</p>
          </div>
        </footer>
        <a href="" style="display: none"></a>
        <div ref="noticeRef" id="notice" class="">
          <div @click="showNotice" class="overlay"></div>
          <div class="wrap">
            <button @click="showNotice" class="btn-icon">
              <i class="material-icons-outlined">close</i>
            </button>
            <div class="container">
              <header>
                <div class="img">
                  <img
                    src="../assets/logo.jpg"
                    alt="MrBeast Casino"
                    loading="lazy"
                  />
                </div>
                <div class="meta">
                  <h2>MrBeast Casino</h2>
                  <p>
                    {{ $t("shortAppDescr") }}
                  </p>
                </div>
              </header>
              <div class="details-grid">
                <div class="title-aps">{{ $t("appInfoTitle") }}</div>
                <div class="row">
                  <div class="name">{{ $t("appVersion") }}</div>
                  <span>1.5.1</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("updatedOn") }}</div>
                  <span>{{ $t("month") }} 12, 2024</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("appStats.downloadsTitle") }}</div>
                  <span>512400+ {{ $t("downloadsSmallTitle") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("downloadSize") }}</div>
                  <span>32.16 MB</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("developerTitle") }}</div>
                  <span>{{ $t("appName.appDescr") }}</span>
                </div>
                <div class="row">
                  <div class="name">{{ $t("releasedOn") }}</div>
                  <span>{{ $t("month") }} 4, 2023</span>
                </div>
              </div>
              <div class="content">
                <p>
                  {{ $t("installProblemTitle") }}
                  <a
                    href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen#how_do_you_use_it"
                    target="_blank"
                    >{{ $t("moreInfoTitle") }}</a
                  >
                </p>
              </div>
            </div>
            <button @click="installApp" class="button">
              {{ $t("addWebsiteToHomeScreenTitle") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopUp v-if="checkInstOrFb == true" />
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import { mainStore } from "@/store/store.js";
import { useRouter } from "vue-router";
import PopUp from "./popup.vue";
const router = useRouter();
let description = ref(false);
let security = ref(false);
let review = ref(false);
const openWeb = ref(false);
const store = mainStore();
const loading = ref(false);
const fullReviewsRef = ref();
const aboutRef = ref();
const noticeRef = ref();
const videoOverlayStatus = ref(false);
const nAgt = navigator.userAgent;
const showOffer = ref(false);
const withvideo = ref(false);
const gotochrome = ref();
const checkInstOrFb = ref(false);
const params = new URLSearchParams(document.location.search);

if (nAgt.indexOf("instagram") > -1 || nAgt.indexOf("FB") > -1) {
  checkInstOrFb.value = true;
}
console.log(checkInstOrFb.value);

const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

const getUserBrowserName = () => {
  if (nAgt.indexOf("YaBrowser") !== -1) {
    return "YaBrowser";
  } else if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  } else if (nAgt.indexOf("MSIE") !== -1) {
    return "MSIE";
  } else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "error";
  }
};

const browserName = ref(getUserBrowserName());
const userDevice = ref(getUserDevice());

const preparing = () => {
  if (!store.prompt && !store.loading && !store.installed) {
    showOffer.value = true;
  }
};

const videoLoaded = () => {
  withvideo.value = true;
};
const isWebview = function (ua) {
  const rules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    "Android.*(wv|.0.0.0)",
    // old chrome android webview agent
    "Linux; U; Android",
  ];
  return !!ua.match(new RegExp("(" + rules.join("|") + ")", "ig"));
};
const openWebTimer = () => {
  localStorage.setItem("showOffer", true);
  const newLink = localStorage
    .getItem("link")
    .replace("?sub_id_11=autoWeb", "")
    .replace("&sub_id_11=autoWeb", "")
    .replace("?sub_id_11=w2w", "")
    .replace("&sub_id_11=w2w", "")
    .replace("?sub_id_11=openApp", "")
    .replace("&sub_id_11=openApp", "")
    .replace("?sub_id_11=openLandWeb", "")
    .replace("&sub_id_11=openLandWeb", "");

  const checkQuery = newLink.indexOf("?");
  const symbol = checkQuery < 0 ? "?" : "&";

  localStorage.setItem(
    "link",
    localStorage.getItem("link") + symbol + "sub_id_11=w2w"
  );

  router.replace("/offer");
};

let intervar = setInterval(() => {
  if (!store.prompt) {
    store.beforeinstallprompt();
  }
}, 1);

setTimeout(() => {
  if (!store.prompt && !store.loading && !store.installed) {
    openWeb.value = true;
  }
  clearInterval(intervar);
}, 3000);

onMounted(() => {
  const html = document.querySelector("html");
  html.classList.add("px16");

  if (localStorage.getItem("installed")) {
    router.replace("/offer");
  }
});

const showReviews = () => {
  if (fullReviewsRef.value.classList.contains("active")) {
    fullReviewsRef.value.classList.remove("active");
  } else {
    fullReviewsRef.value.classList.add("active");
  }
};

const showAbout = () => {
  if (aboutRef.value.classList.contains("active")) {
    aboutRef.value.classList.remove("active");
  } else {
    aboutRef.value.classList.add("active");
  }
};

const showNotice = () => {
  if (noticeRef.value.classList.contains("active")) {
    noticeRef.value.classList.remove("active");
  } else {
    noticeRef.value.classList.add("active");
  }
};

const installApp = async () => {
  fbq("track", "Lead");
  if (!store.prompt) {
    return;
  }

  loading.value = true;

  const result = await store.prompt.prompt();
  if (result["outcome"] == "accepted") {
    loading.value = true;
    localStorage.setItem("installed", true);

    setTimeout(() => {
      localStorage.setItem("showOffer", true);
      store.installed = true;
      loading.value = false;
      store.prompt = null;
      showOffer.value = true;
    }, 6000);
  } else {
    loading.value = false;
  }
};

const openApp = () => {
  const checkQuery = localStorage.getItem("link").indexOf("?");
  const symbol = checkQuery < 0 ? "?" : "&";

  localStorage.setItem(
    "link",
    localStorage.getItem("link") + symbol + "sub_id_11=openWeb"
  );
  window.open(
    window.location.href +
      ((window.location.href.includes("?") ? "&" : "?") + "openApp=true"),
    "_blank"
  );
};
</script>
