<template>
  <router-view />
  <a href="" ref="gotochrome" style="display: none"></a>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onBeforeMount, onUnmounted } from "vue";
import { mainStore } from "@/store/store.js";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const router = useRouter();
const store = mainStore();
store.beforeinstallprompt();
const gotochrome = ref();
const userDevice = ref();
const nAgt = navigator.userAgent;

onBeforeMount(() => {
  var userLang = navigator.language || navigator.userLanguage;

  if (userLang == "es") {
    locale.value = "es";
  } else {
    locale.value = "en";
  }
});

const getUserDevice = () => {
  if (nAgt.indexOf("Android") !== -1) {
    return "Android";
  } else {
    return "IOS";
  }
};

userDevice.value = getUserDevice();

const isPwa = store.checkDisplay();
const isLink = localStorage.getItem("link");
const link = "https://ida.wake-app.net/ggndGn";
const params = new URLSearchParams(document.location.search);
if (userDevice.value != "Android") {
  localStorage.setItem("showOffer", true);
  router.replace("/offer");
}

if (!localStorage.getItem("params")) {
  localStorage.setItem("params", window.location.search);
}

const showOffer = () => {
  router.replace("/offer");
};

const showGame = () => {
  router.replace("/game");
};

const showStore = () => {
  router.replace("/store");
};

const startApp = () => {
  if (!localStorage.getItem("link")) {
    const offer_link = link + localStorage.getItem("params");
    localStorage.setItem("link", offer_link);
  }

  if (
    localStorage.getItem("showOffer") ||
    (isPwa && store.installed && isLink)
  ) {
    if (isPwa) {
      if (isLink) {
        if (isLink.indexOf("openApp") < 0) {
          const newLink = isLink
            .replace("?sub_id_11=w2w", "")
            .replace("&sub_id_11=w2w", "")
            .replace("?sub_id_11=openWeb", "")
            .replace("&sub_id_11=openWeb", "")
            .replace("?sub_id_11=openLandWeb", "")
            .replace("&sub_id_11=openLandWeb", "");

          const checkQuery = newLink.indexOf("?");
          const symbol = checkQuery < 0 ? "?" : "&";

          localStorage.setItem("link", newLink + symbol + "sub_id_11=openApp");
        }
      }
    } else {
      if (isLink) {
        if (isLink.indexOf("openWeb") < 0) {
          const newLink = isLink
            .replace("?sub_id_11=w2w", "")
            .replace("&sub_id_11=w2w", "")
            .replace("?sub_id_11=openApp", "")
            .replace("&sub_id_11=openApp", "")
            .replace("?sub_id_11=openLandWeb", "")
            .replace("&sub_id_11=openLandWeb", "");

          const checkQuery = newLink.indexOf("?");
          const symbol = checkQuery < 0 ? "?" : "&";

          localStorage.setItem("link", newLink + symbol + "sub_id_11=openWeb");
        }
      }
    }

    showOffer();
  } else if (!isPwa) {
    if (params.get("redirect")) {
      localStorage.setItem("openStore", true);
    }

    if (params.get("openLand")) {
      showGame();
    } else {
      showStore();
    }
  } else {
    params.get("redirect");
    if (params.get("redirect")) {
      localStorage.setItem("openStore", true);
    }
    showStore();
  }
};

startApp();
</script>
<style lang="scss" scoped></style>
