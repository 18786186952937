<template>
  <div class="popup">
    <div class="popup-inner">
      <div class="img">
        <img src="../assets/logo.jpg" alt="MrBeast Casino" />
      </div>
      <div class="text1">To install the application</div>
      <div class="text2">you need to go to the browser</div>
      <button class="button pop-btn" @click="openWeb()">Go to browser</button>
    </div>
  </div>
</template>

<script setup>
const openWeb = () => {
  window.open(
    `intent://navigate?url=${window.location.hostname}/${
      localStorage.getItem("params") ? localStorage.getItem("params") : ""
    }#Intent;scheme=googlechrome;end;`
  );
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  .text2 {
    font-weight: 400;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .pop-btn {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .text1 {
    font-weight: 400;
    font-size: 1rem;
  }
  .img {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 10%;
      max-width: 10rem;
    }
  }
  .popup-inner {
    text-align: center;
    border-radius: 3%;
    background: #131313;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
  }
}
</style>
