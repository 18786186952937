import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes";
import "./style.scss";
import { createI18n } from "vue-i18n";

import { languages } from "./i18n";
import { defaultLocale } from "./i18n";

const messages = Object.assign(languages);
const pinia = createPinia();
const app = createApp(App);

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

app.use(pinia).use(i18n).use(router).mount("#app");
