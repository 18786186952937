/* eslint-disable no-console */
import { register } from "register-service-worker";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {},

  registered() {},
  cached() {},
  updatefound() {},
  updated() {},
  offline() {},
  error(error) {},
});

self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    self.skipWaiting();
  }
});
